import { etherBalanceLoaded, web3AccountLoaded, web3Loaded } from "../store/actions";
import Web3 from 'web3'

export const loadWeb3 = async (dispatch) => {
    if(typeof window.ethereum!=='undefined'){
      const web3 = new Web3(window.ethereum)
  
      await window.ethereum.enable();
  
      dispatch(web3Loaded(web3))
      return web3
    } else {
      window.alert('Please install MetaMask')
      window.location.assign("https://metamask.io/")
    }
}
  
export const loadAccount = async (web3, dispatch) => {
    const accounts = await web3.eth.getAccounts()
    console.log("Accounts to load : ");
    console.log(accounts);
    const account = accounts[0]
    dispatch(web3AccountLoaded(account))
    return account
}

export const loadBalance = async (dispatch, web3, account) => {
    const etherBalance = await web3.eth.getBalance(account)

    dispatch(etherBalanceLoaded(etherBalance))
}
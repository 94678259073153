import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, OverlayTrigger, Container } from 'react-bootstrap';
import { accountSelector } from '../store/selectors'

import logoDesktop from '../assets/logo-desktop.png';
import logoMobile from '../assets/logo-mobile.png';
import { times } from 'lodash-es';

const chopOff = (address) => {
  console.log(address);
  if (address !== undefined) {
    return address.substring(0, 6) + "..." + address.substring(address.length - 4, address.length);
  } else {
    return "";
  }
}

class Navbar extends Component {
  render() {

    const AddressMenu = () => {
    
      return(
        <Card style={{zIndex:999, maxWidth: "300px", marginRight: "10px", float: "right"}}>
          <Card.Body>
            <Container className={this.props.account != undefined ? '' : 'hidden'}>
                <b className="row">Your Wallet</b>
                <a className="row p-1"
                style={{wordBreak:"break-all"}}
                  href={"https://snowtrace.io/address/" + this.props.account}>
                    { this.props.account }
                </a>
                {/* <button className="btn btn-danger btn-block btn-sm my-3">Log out</button> */}
              </Container>
          </Card.Body>
        </Card>
      )
    }

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-color-hlb">
        <img className="navbar-brand navbar-desktop-logo d-none d-md-block ms-2" src={logoDesktop} />
        <img className="navbar-brand navbar-desktop-logo d-block d-md-none ms-2" src={logoMobile} />
        <OverlayTrigger trigger="click" placement="bottom" overlay={AddressMenu}>
            <ul className="navbar-nav ms-auto me-2">
              <li className="nav-item">
                  <span
                    className={this.props.account != undefined ? "navbar-address d-none d-md-block" : 'hidden'}
                    target="_blank"
                    rel="noopener noreferrer">
                    {this.props.account + " ▾"}
                  </span>
                  <span
                  className={this.props.account != undefined ? 'navbar-address d-block d-md-none' : 'hidden'}
                  target="_blank"
                  rel="noopener noreferrer">
                  { chopOff(this.props.account) + " ▾"}
                </span>
                <span
                  className={this.props.account != undefined ? 'hidden' : 'navbar-address'}>
                  { "Not connected ▾"}
                </span>
              </li>
            </ul>
        </OverlayTrigger>
      </nav>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: accountSelector(state)
  }
}

export default connect(mapStateToProps)(Navbar)

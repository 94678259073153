import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tabs, Tab, Spinner } from 'react-bootstrap'
import {
  loadBalances,
  depositEther,
  depositToken,
  withdrawEther,
  withdrawToken
} from '../store/interactions'
import {
  exchangeSelector,
  tokenSelector,
  accountSelector,
  web3Selector,
  etherBalanceSelector,
  tokenBalanceSelector,
  exchangeEtherBalanceSelector,
  exchangeTokenBalanceSelector,
  balancesLoadingSelector,
  etherDepositAmountSelector,
  etherWithdrawAmountSelector,
  tokenDepositAmountSelector,
  tokenWithdrawAmountSelector,
} from '../store/selectors'
import {
  etherDepositAmountChanged,
  etherWithdrawAmountChanged,
  tokenDepositAmountChanged,
  tokenWithdrawAmountChanged,
} from '../store/actions'
import { checkIfDecimal } from '../helpers'

class Balance extends Component {

  showForm = (props, state) => {
    const {
      dispatch,
      exchange,
      web3,
      account,
      etherBalance,
      tokenBalance,
      exchangeEtherBalance,
      exchangeTokenBalance,
      etherDepositAmount,
      token,
      tokenDepositAmount,
      etherWithdrawAmount,
      tokenWithdrawAmount
    } = props
  
    return(
      <Tabs defaultActiveKey="deposit" className="bg-dark text-white small">
  
        <Tab eventKey="deposit" title="Deposit" className="bg-dark text-white tab-pane">
          <table className="table table-dark table-sm small">
            <thead>
              <tr>
                <th>Token</th>
                <th>Wallet</th>
                <th>Market</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>AVAX</td>
                <td>{etherBalance}</td>
                <td>{exchangeEtherBalance}</td>
              </tr>
            </tbody>
          </table>
  
          <form className="row" onSubmit={async (event) => {
            event.preventDefault()
            this.setState({depositAVAX : true})

            await depositEther(dispatch, exchange, web3, etherDepositAmount, account).catch(e => { console.log(e) })

            await this.loadBlockchainData();

            this.setState({depositAVAX : false})
          }}>
            <div className="col-12 col-sm pr-sm-2 mb-2">
              <input
              type="number"
              step="0.0001"
              placeholder="AVAX Amount"
              onChange={(e) => { dispatch( etherDepositAmountChanged(e.target.value) ) } }
              className="form-control form-control-sm bg-dark text-white"
              required />
            </div>
            <div className="col-12 col-sm-auto pl-sm-0 mx-lg-1 my-2 my-sm-0">
              <button type="submit" className="btn btn-primary btn-block btn-sm">
                <span className={this.state.depositAVAX ? 'hidden': ''}>Deposit</span>
                <Spinner
                  className={this.state.depositAVAX ? '': 'hidden'}
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            </div>
          </form>
  
          <table className="table table-dark table-sm small">
            <thead>
              <tr>
                <th>Token</th>
                <th>Wallet</th>
                <th>Market</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LBC</td>
                <td>{etherBalance}</td>
                <td>{exchangeEtherBalance}</td>
              </tr>
            </tbody>
          </table>
  
          <form className="row" onSubmit={async (event) => {
            event.preventDefault()
            
            this.setState({depositLBC : true});

            await depositToken(dispatch, exchange, web3, token, tokenDepositAmount, account);

            await this.loadBlockchainData();

            this.setState({depositLBC : false})
          }}>
            <div className="col-12 col-sm pr-sm-2 mb-2">
              <input
              type="number"
              step="0.0001"
              placeholder="LBC Amount"
              onChange={(e) => dispatch( tokenDepositAmountChanged(e.target.value) )}
              className="form-control form-control-sm bg-dark text-white"
              required />
            </div>
            <div className="col-12 col-sm-auto pl-sm-0 mx-lg-1 my-2 my-sm-0">
              <button type="submit" className="btn btn-danger btn-block btn-sm">
              <span className={this.state.depositLBC ? 'hidden': ''}>Deposit</span>
                <Spinner
                  className={this.state.depositLBC ? '': 'hidden'}
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            </div>
          </form>
  
        </Tab>
  
        <Tab eventKey="withdraw" title="Withdraw" className="bg-dark">
  
          <table className="table table-dark table-sm small">
            <thead>
              <tr>
                <th>Token</th>
                <th>Wallet</th>
                <th>Market</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>AVAX</td>
                <td>{etherBalance}</td>
                <td>{exchangeEtherBalance}</td>
              </tr>
            </tbody>
          </table>
          
          <form className="row" onSubmit={async (event) => {
            event.preventDefault()
            this.setState({withdrawAVAX : true})

            await withdrawEther(dispatch, exchange, web3, etherWithdrawAmount, account)
            await this.loadBlockchainData();

            this.setState({withdrawAVAX : false})
          }}>
            <div className="col-12 col-sm pr-sm-2 mb-2">
              <input
              type="number"
              step="0.0001"
              placeholder="AVAX Amount"
              onChange={(e) => {
                dispatch( etherWithdrawAmountChanged(e.target.value) )}}
              className="form-control form-control-sm bg-dark text-white"
              required />
              </div>
            <div className="col-12 col-sm-auto pl-sm-0 my-2 my-sm-0">
              <button type="submit" className="btn btn-primary btn-block btn-sm">
              <span className={this.state.withdrawAVAX ? 'hidden': ''}>Withdraw</span>
              <Spinner
                className={this.state.withdrawAVAX ? '': 'hidden'}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </button>
            </div>
          </form>
  
          <table className="table table-dark table-sm small">
          <thead>
              <tr>
                <th>Token</th>
                <th>Wallet</th>
                <th>Market</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LBC</td>
                <td>{etherBalance}</td>
                <td>{exchangeEtherBalance}</td>
              </tr>
            </tbody>
          </table>
  
          <form className="row" onSubmit={async (event) => {
            event.preventDefault()
            this.setState({withdrawLBC : true})

            await withdrawToken(dispatch, exchange, web3, token, tokenWithdrawAmount, account)
            await this.loadBlockchainData();

            this.setState({withdrawLBC : false})
          }}>
            <div className="col-12 col-sm pr-sm-2 mb-2">
              <input
              type="number"
              step="0.0001"
              placeholder="LBC Amount"
              onChange={(e) => dispatch( tokenWithdrawAmountChanged(e.target.value) )}
              className="form-control form-control-sm bg-dark text-white"
              required />
            </div>
            <div className="col-12 col-sm-auto pl-sm-0 my-2 my-sm-0">
              <button type="submit" className="btn btn-danger btn-block btn-sm">
              <span className={this.state.withdrawLBC ? 'hidden': ''}>Withdraw</span>
              <Spinner
                className={this.state.withdrawLBC ? '': 'hidden'}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </button>
            </div>
          </form>
  
        </Tab>
      </Tabs>
    )
  }

  constructor(props) {
    super(props);
    this.state = {depositAVAX: false, depositLBC: false, withdrawAVAX: false, withdrawLBC: false};
  }

  componentWillMount() {
    this.loadBlockchainData()
  }

  async loadBlockchainData() {
    const { dispatch, web3, exchange, token, account } = this.props
    await loadBalances(dispatch, web3, exchange, token, account)
  }

  render() {
    return (
      <div className="card bg-dark text-white">
        <div className="card-header">
          Balance
        </div>
        <div className="card-body">
          {this.props.showForm ? this.showForm(this.props) : <Spinner />}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const balancesLoading = balancesLoadingSelector(state)

  return {
    account: accountSelector(state),
    exchange: exchangeSelector(state),
    token: tokenSelector(state),
    web3: web3Selector(state),
    etherBalance: etherBalanceSelector(state),
    tokenBalance: tokenBalanceSelector(state),
    exchangeEtherBalance: exchangeEtherBalanceSelector(state),
    exchangeTokenBalance: exchangeTokenBalanceSelector(state),
    balancesLoading,
    showForm: !balancesLoading,
    etherDepositAmount: etherDepositAmountSelector(state),
    etherWithdrawAmount: etherWithdrawAmountSelector(state),
    tokenDepositAmount: tokenDepositAmountSelector(state),
    tokenWithdrawAmount: tokenWithdrawAmountSelector(state),
  }
}

export default connect(mapStateToProps)(Balance)

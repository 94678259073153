export function purchaseProcessing(state) {
    return {
      type: 'PURCHASE_PROCESSING',
      state
    }
}

export function purchaseStatus(status) {
    return {
      type: 'PURCHASE_STATUS',
      status
    }
}

export function purchaseCompletedLoaded(list) {
    return {
      type: 'PURCHASE_COMPLETED_LOADED',
      list
    }
}

export function purchaseCompletedAdded(purchase) {
    return {
      type: 'PURCHASE_COMPLETED_ADDED',
      purchase
    }
}

export function volumesLoaded(volumes) {
    return {
      type: 'VOLUMES_LOADED',
      volumes
    }
}

export function volumeAdded(volume) {
    return {
      type: 'VOLUMES_ADDED',
      volume
    }
}

export function priceHistoryLoaded(prices) {
    return {
        type: 'PRICE_HISTORY_LOADED',
        prices
      }
}

export function priceChanged(newPrice) {
    return {
      type: 'PRICE_CHANGED',
      newPrice
    }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, OverlayTrigger, Container, Row } from 'react-bootstrap';
import { accountSelector } from '../store/selectors'

import {
    accountConnect,
    loadWeb3,
    loadAccount,
    loadToken,
    loadExchange
  } from '../store/interactions'

import logoMetamask from '../assets/metamask-fox.svg';

class Connect extends Component {

    async loadBlockchainData(dispatch) {
        const web3 = await loadWeb3(dispatch)
        const networkId = await web3.eth.net.getId()
        await loadAccount(web3, dispatch)
        const token = await loadToken(web3, networkId, dispatch)
        if(!token) {
          window.alert('Token smart contract not detected on the current network. Please select another network with Metamask.')
          return
        }
        const exchange = await loadExchange(web3, networkId, dispatch)
        if(!exchange) {
          window.alert('Exchange smart contract not detected on the current network. Please select another network with Metamask.')
          return
        }

        await accountConnect(dispatch);
    }

    render() {
      return (
        <div className="present-connection align-items-center">
            <Container className="connect-card">
                <div className="card bg-dark text-white">
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <img className="metamask-fox" src={logoMetamask} />
                        </div>
                        <Row className="m-2">
                            <button onClick={(e) => this.loadBlockchainData(this.props.dispatch)} className="btn btn-primary btn-sm btn-block">Connect Metamask</button>
                        </Row>
                    </div>
                </div>
            </Container>
        </div>
      )
    }
}

function mapStateToProps(state) {
    return {
      account: accountSelector(state)
    }
  }
  
  export default connect(mapStateToProps)(Connect)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Spinner from './Spinner'
import {
  orderBookSelector,
  orderBookLoadedSelector,
  exchangeSelector,
  accountSelector,
  orderFillingSelector
} from '../store/selectors'
import { fillOrder } from '../store/interactions'

const noOrder = () => {
  return(
  <p>There are no ongoing order</p>
  )
}

class OrderBook extends Component {
  render() {

    const showOrderBook = (props) => {
      const { orderBook } = props
    
      const renderOrder = (order, props) => {
        const { dispatch, exchange, account } = props
      
        console.log("single order")
        console.log(order);
      
        const TooltipOrder = () => {
          return(<Tooltip id={order.id}>
          {`Click here to ${order.orderFillAction}`}
          </Tooltip>);
        }
      
        return(
          <OverlayTrigger
            key={order.id}
            placement='bottom'
            overlay={ 
              <Tooltip id={order.id}>
              {`Click here to ${order.orderFillAction}`}
              </Tooltip>
             }
          >
            <tr
              key={order.id}
              className="order-book-order"
              onClick={(e) => fillOrder(dispatch, exchange, order, account)}
            >
              <td>{order.tokenAmount}</td>
              <td className={`text-${order.orderTypeClass}`}>{order.tokenPrice}</td>
              <td>{order.etherAmount}</td>
            </tr>
          </OverlayTrigger>
        )
      }
    
      return(
        <tbody>
          <tr>
            <th>Selling</th>
            <th>LBC/AVAX</th>
            <th>AVAX</th>
          </tr>
          {orderBook.sellOrders.map((order) => renderOrder(order, props))}
          <tr>
            <th>Buying</th>
            <th>LBC/AVAX</th>
            <th>AVAX</th>
          </tr>
          {orderBook.buyOrders.map((order) => renderOrder(order, props))}
        </tbody>
      )
    }

    console.log("prop orderbook")
    console.log(this.props);

    return (
      <div className="vertical">
        <div className="card bg-dark text-white">
          <div className="card-header">
            Order Book
          </div>
          <div className="card-body order-book">
            <table className="table table-dark table-sm table-stripped small">
              { this.props.showOrderBook ? showOrderBook(this.props) : <Spinner type='table' /> }
            </table>
            { this.props.showOrderBook && this.props.orderBook?.sellOrders?.length == 0 && this.props.orderBook?.buyOrders?.length == 0 ? noOrder() : "" }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const orderBookLoaded = orderBookLoadedSelector(state)
  const orderFilling = orderFillingSelector(state)

  return {
    orderBook: orderBookSelector(state),
    showOrderBook: orderBookLoaded && !orderFilling,
    exchange: exchangeSelector(state),
    account: accountSelector(state)
  }
}

export default connect(mapStateToProps)(OrderBook);












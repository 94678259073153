import { priceChanged, priceHistoryLoaded, purchaseCompletedAdded, purchaseCompletedLoaded, purchaseProcessing, purchaseStatus, volumeAdded, volumesLoaded } from "../store/actions/DecentralizedOffering";
import Web3 from 'web3'
import { loadSaleStatus } from "./sale.service";
import { getWalletLBCBalance } from "./lbc.service";
import { loadBalance } from "./web3.service";
import moment from 'moment'
import { contractNotReady } from "../store/actions/Metamask";

export const buyLBC = async (dispatch, web3, exchange, token, account, avaxAmount) => {
    dispatch(purchaseProcessing(true));
    try {
        await exchange.methods.buyTokens().send({ from: account,  value: Web3.utils.toWei(avaxAmount, 'ether') })
        .on('transactionHash', (hash) => {
            console.log("GOT TX HASH");
            dispatch(purchaseStatus("success"));
        })
        .on('error', (error) => {
            console.error(error)
            dispatch(purchaseStatus("error"));
        });
    } catch (e) {
        dispatch(purchaseStatus("error"));
    }
}

export const getVolumes = async (dispatch, exchange) => {
    // Fetch filled orders with the "Trade" event stream
  const tradeStream = await exchange.getPastEvents('PurchaseCompleted', { fromBlock: 0, toBlock: 'latest' })
  console.log("AHAHOUUIUOUI")
  console.log(tradeStream);
  // Format filled orders
  const filledOrders = tradeStream.map((e) => { return {...e.returnValues} } )

    dispatch(purchaseCompletedLoaded(filledOrders));

    console.log(filledOrders);

    var accumulate = {}
    var volumes = [];
    for (var i=0; i < filledOrders.length; i++) {
        var date = moment.unix(filledOrders[i].timestamp).format("YYYY-MM-DD")
        if (accumulate[date] === undefined)
            accumulate[date] = 0;

        accumulate[date] += Number(Web3.utils.fromWei(filledOrders[i].tokenAmount));
    }

    for (const [key, value] of Object.entries(accumulate)) {
        volumes.push( { time: key, value: value, color: 'rgba(0, 150, 136, 0.8)' }) 
    }

    console.log("ACCU")
    console.log(volumes);

    dispatch(volumesLoaded(volumes));
}

export const getPriceHistory = async (dispatch, exchange) => {
    const initialRatiosStream = await exchange.getPastEvents('SaleConfigured', { fromBlock: 0, toBlock: 'latest' })
    const allPricesChangesStream = await exchange.getPastEvents('ThresholdReached', { fromBlock: 0, toBlock: 'latest' })

    const initialRatios = initialRatiosStream.map((e) => { return {...e.returnValues} } )
    const allPricesChanges = allPricesChangesStream.map((e) => { return {...e.returnValues} } )

    console.log("InitialRatio is ")
    console.log("Onngi bonngi")
    console.log(initialRatios);

    if (initialRatios.length <= 0) {
        dispatch(contractNotReady());
        return;
    }

    console.log(initialRatios[initialRatios.length - 1].startingRatio);

    const startingInfo = {time: moment.unix(initialRatios[initialRatios.length - 1].timestamp).format("YYYY-MM-DD"), value: 1 / Number(Web3.utils.fromWei(initialRatios[initialRatios.length - 1].startingRatio, 'Gwei'))};

    var priceHistory = [startingInfo];

    const priceStream = await exchange.getPastEvents('ThresholdReached', { fromBlock: 0, toBlock: 'latest' })
    const pricesRetrieved = priceStream.map((e) => { return {...e.returnValues} } )

    var curDate = priceHistory[0].time;

    for (var i = 0; i < pricesRetrieved.length; i) {
        var dateToReach = moment.unix(pricesRetrieved[i].timestamp).format("YYYY-MM-DD")
        console.log("CACA")
        if (moment(curDate).isSame(dateToReach)) {
            priceHistory.pop();
            priceHistory.push({time: dateToReach, value: 1 / Number(Web3.utils.fromWei(pricesRetrieved[i].newRatio, 'Gwei'))});
            i++;
        } else {
            curDate = moment(curDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
            console.log(curDate)
            priceHistory.push({time: curDate, value: priceHistory[priceHistory.length - 1].value});
        }
    }

    var today = moment().format("YYYY-MM-DD");

    while (!moment(curDate).isSame(today)) {
        curDate = moment(curDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
        priceHistory.push({time: curDate, value: priceHistory[priceHistory.length - 1].value});
    }

    dispatch(priceHistoryLoaded(priceHistory));
}

export const subscribeToEvents = async (exchange, dispatch) => {

    var purchaseLastHash = undefined;
    var thresholdLastHash = undefined;

    console.log("Subscribing to events");

    exchange.events.PurchaseCompleted({fromBlock: 'latest'}, async (error, event) => {
        if (event.transactionHash === purchaseLastHash)
            return;
        
        purchaseLastHash = event.transactionHash;
        console.log("EVENT PURCHASE FIRED")
        await loadSaleStatus(dispatch, exchange);
        dispatch(purchaseCompletedAdded({...event.returnValues}));
        dispatch(volumeAdded({time: moment.unix(event.returnValues.timestamp).format("YYYY-MM-DD"), value: Number(Web3.utils.fromWei(event.returnValues.tokenAmount)), color: 'rgba(0, 150, 136, 0.8)'}))
    })
  
    exchange.events.ThresholdReached({fromBlock: 'latest'}, async (error, event) => {
        console.log("EVENT THRESHOLD FIRED")
        if (event.transactionHash === thresholdLastHash)
            return;
    
        thresholdLastHash = event.transactionHash;
        console.log(event);
        console.log("SENXYDU")
        console.log({time: moment.unix(event.returnValues.timestamp), value: 1 / Number(Web3.utils.fromWei(event.returnValues.newRatio, 'Gwei')) })
        dispatch(priceChanged({time: moment.unix(event.returnValues.timestamp).format("YYYY-MM-DD"), value: 1 / Number(Web3.utils.fromWei(event.returnValues.newRatio, 'Gwei')) }));
    })
}
import React, { useEffect, useRef, Component } from 'react';
import ReactDOM from 'react-dom';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { connect } from 'react-redux'
import {
    priceChartLoadedSelector,
    priceChartSelector
  } from '../store/selectors'
  import { priceData } from './PriceChart.config'

class TradingChart extends Component {

    constructor(props) {
        super(props);
        this.chartContainerRef = React.createRef();
        this.chart = React.createRef();
        this.container = React.createRef();
        this.resizeObserver = React.createRef();
    }

    componentDidMount() {

        console.log("chartContainerRef")
        console.log(this.chartContainerRef.current);

        this.chart.current = createChart(this.chartContainerRef.current, {
          width: this.chartContainerRef.current.clientWidth,
          height: this.chartContainerRef.current.clientHeight,
          handleScroll: true,
          handleScale: true,
          layout: {
            backgroundColor: '#212529',
            textColor: 'rgba(255, 255, 255, 0.9)',
          },
          grid: {
            vertLines: {
              color: '#334158',
            },
            horzLines: {
              color: '#334158',
            },
          },
          crosshair: {
            mode: CrosshairMode.Normal,
          },
          priceScale: {
            borderColor: '#485c7b',
          },
          timeScale: {
            borderColor: '#485c7b',
          },
        });
    
        console.log(this.chart.current);
    
        const candleSeries = this.chart.current.addCandlestickSeries({
          upColor: '#4bffb5',
          downColor: '#ff4976',
          borderDownColor: '#ff4976',
          borderUpColor: '#4bffb5',
          wickDownColor: '#838ca1',
          wickUpColor: '#838ca1',
        });
    
        candleSeries.setData(priceData);
    
        const volumeSeries = this.chart.current.addHistogramSeries({
          color: '#182233',
          lineWidth: 2,
          priceFormat: {
            type: 'volume',
          },
          overlay: true,
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          },
        });
    
        //volumeSeries.setData(undefined);

        this.resizeObserver.current = new ResizeObserver(entries => {

            const { width, height } = entries[0].contentRect;

            console.log("width : " + width);
            console.log("height: " + height);
            this.chart.current.applyOptions({ width, height });
            this.chart.current.timeScale().fitContent();
        });
    
        this.resizeObserver.current.observe(this.chartContainerRef.current);
        
    }

    render() {
        console.log("Trading chart values");
        console.log(this.props.priceChart);

        return(
            <div className="card bg-dark text-white">
            <div className="card-header">
              Price Chart
            </div>
            <div ref={this.container} className="card-body">
                <div ref={this.chartContainerRef} className="price-chart price-chart-size" />
            </div>
          </div>
        );
    }
}

function mapStateToProps(state) {

    return {
      priceChartLoaded: priceChartLoadedSelector(state),
      priceChart: priceChartSelector(state),
    }
  }

export default connect(mapStateToProps)(TradingChart)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Spinner from './Spinner'
import { Col, OverlayTrigger, Row, Tooltip, Badge, Container, ProgressBar, Modal, Button } from 'react-bootstrap'
import {
  contractReadySelector,
  currentCapLoadedSelector,
  exchangeSelector,
  filledOrdersLoadedSelector,
  filledOrdersSelector,
  lbcLeftAmountLoadedSelector,
  priceAvaxConfiguredSelector,
  priceAvaxLoadedSelector,
  priceAvaxUSDLoadedSelector
} from '../store/selectors'

import LogoLBC from '../assets/logo_lbc.png'
import { loadSaleStatus } from '../services/sale.service'
import {priceDollarComputedSelector, lbcThresholdLeftAmountLoadedSelector} from '../store/selectors'

const showFilledOrders = (filledOrders) => {
  return(
    <tbody>
      { filledOrders.map((order) => {
        return(
          <tr className={`order-${order.id}`} key={order.id}>
            <td className={`text-${order.tokenPriceClass}`}>{order.tokenPrice}</td>
            <td>{order.tokenAmount}</td>
            <OverlayTrigger
            key={order.id}
            placement='bottom'
            overlay={ 
              <Tooltip>
              {order.fullTimestamp}
              </Tooltip>
             }
            >
              <td className="text-muted">{order.formattedTimestamp}</td>
            </OverlayTrigger>
          </tr>
        )
      }) }
    </tbody>
  )
}


class SaleStatus extends Component {

  constructor() {
    super();
    this.state = {
      showNetworkError:false
    }
  }

  async componentWillMount() {
    try {
    await loadSaleStatus(this.props.dispatch, this.props.exchange);
    } catch (e) {
      this.handleShow();
    }
  }

  handleClose = () => {this.setState({showNetworkError: false})}
  handleShow = () => {this.setState({showNetworkError: true})}

  render() {
    console.log("price dollar lbc")
    console.log(this.props.priceDollarLBC);
    return (
      <div className="vertical">
        <div className="card bg-dark text-white">
          <div className="card-header">
            Sale
          </div>
          <div className="card-body">
          <Container>
            <Col>
              <img src={LogoLBC} style={{width : "30px"}} />
              <span className="p-1 mt-2" style={{fontSize: "17px"}}>Luck Ball Coin (LBC)</span>
            </Col>
            <Container>
              <Row className="d-flex justify-content-center mt-2">
                <Col className="d-flex">
                  <h3 className="text-right" style={{margin: "0px"}}>${this.props.priceDollarLBC}</h3>
                </Col>
                <Col className="d-flex align-items-center">
                  <Badge className="vertical-center" bg="success">▲ {this.props.priceDollarLBC < 0.26 ? (0).toFixed(2) : (this.props.priceDollarLBC / 0.26 * 100 - 100).toFixed(2)} %</Badge>
                </Col>
              </Row>
              <Row>
                <span style={{fontSize : "12px"}}>${this.props.priceDollarAvax} AVAX</span>
              </Row>
              <Row>
                <span style={{fontSize : "12px"}}>{this.props.priceAvaxLBC} AVAX/LBC</span>
              </Row>
            </Container>
            <Row>
              <span className="mt-3" style={{fontSize: "17px"}}>Current Cap</span>
            </Row>
            <Container className="p-2">
              <ProgressBar variant="success" now={((20120 - this.props.lbcThreshold)/20120*100).toFixed(2)}></ProgressBar>
              <Row className="d-flex justify-content-center">
                <span style={{textAlign: "center"}}>{(20120 - this.props.lbcThreshold).toFixed(2)}/20120 LBC</span>
              </Row>
            </Container>
            <Row className="mt-3">
              <Col>
                <span>Next Price ($)</span>
              </Col>
              <Col>
                <span style={{textAlign: "right"}}>{(Number(this.props.priceDollarLBC) + 0.01).toFixed(2)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>Next Price (AVAX)</span>
              </Col>
              <Col>
                <span style={{textAlign: "right"}}>{(1 / (this.props.avaxPrice / (Number(this.props.priceDollarLBC) + 0.01))).toFixed(5)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>Cur. Cap</span>
              </Col>
              <Col>
                <span style={{textAlign: "right"}}>{this.props.currentCap}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>LBC Sold</span>
              </Col>
              <Col>
                <span style={{textAlign: "right"}}>{(2515000 - this.props.lbcAvailable).toFixed(2)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>LBC Left</span>
              </Col>
              <Col>
                <span style={{textAlign: "right"}}>{Intl.NumberFormat('en-US', {
                                              notation: "compact",
                                              maximumFractionDigits: 1
                                            }).format(this.props.lbcAvailable)}
                </span>
              </Col>
            </Row>
            </Container>
          </div>
        </div>
        <Modal show={this.state.showNetworkError} onHide={this.handleClose} backdrop="static">
          <Modal.Header>
            <Modal.Title><span className="text-danger">Network Error</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <h2>Woops!</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>There seem to be a network error. Please check that you are connected on the correct network in MetaMask and reload this page.</span>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <Modal show={!this.props.contractReady && ! this.state.showNetworkError} backdrop="static">
          <Modal.Header>
            <Modal.Title><span className="text-danger">Sale Not Started</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <h2>Not yet!</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>The sale hasn't started yet, come back a little bit later!</span>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    filledOrdersLoaded: filledOrdersLoadedSelector(state),
    filledOrders: filledOrdersSelector(state),
    priceDollarLBC: priceDollarComputedSelector(state),
    priceAvaxLBC: priceAvaxLoadedSelector(state),
    priceDollarAvax: priceAvaxUSDLoadedSelector(state),
    avaxPrice: priceAvaxConfiguredSelector(state),
    lbcAvailable: lbcLeftAmountLoadedSelector(state),
    lbcThreshold: lbcThresholdLeftAmountLoadedSelector(state),
    currentCap: currentCapLoadedSelector(state),
    contractReady: contractReadySelector(state),
    exchange: exchangeSelector(state)
  }
}

export default connect(mapStateToProps)(SaleStatus)

// Connected
export function accountConnection(status) {
    return {
      type: 'ACCOUNT_CONNECTED',
      status
    }
}

// WEB3
export function web3Loaded(connection) {
    return {
      type: 'WEB3_LOADED',
      connection
    }
}

export function web3AccountLoaded(account) {
    return {
      type: 'WEB3_ACCOUNT_LOADED',
      account
    }
}

export function contractNotReady() {
  return {
    type: 'CONTRACT_NOT_READY'
  }
}
import React, { useEffect, useRef, Component } from 'react';
import ReactDOM from 'react-dom';
import { Spinner } from 'react-bootstrap';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { connect } from 'react-redux'
import moment from 'moment'
import {
  exchangeSelector,
    newPriceAddedSelector,
    newVolumeAddedSelector,
    priceAvaxUSDLoadedSelector,
    priceChartLoadedSelector,
    priceChartSelector,
    priceDollarComputedSelector,
    priceHistoryLoadedSelector,
    volumesLoadedSelector
  } from '../store/selectors'
  import { lbcPrice, lbcVolumes, priceData } from './PriceChart.config'
import { getPriceHistory, getVolumes } from '../services/decentralizedOffering.service';
import { priceAvaxUSDLoaded } from '../store/actions/SaleStatus';

class IdoChart extends Component {

    constructor(props) {
        super(props);
        this.chartContainerRef = React.createRef();
        this.chart = React.createRef();
        this.container = React.createRef();
        this.resizeObserver = React.createRef();
        this.volumes = React.createRef();
        this.areas = React.createRef();

        this.lastVolume = undefined;
        this.setSkew = false;
        this.volumes.current = undefined;
        this.legenden = undefined;
    }

    componentDidUpdate(prevProps) {
      if (prevProps.volumes !== this.props.volumes && this.volumes) {
        if (!prevProps.volumes || prevProps.volumes?.length <= 0) {
          console.log("VRAIMENTKWA")
          this.volumes.current = this.chart.current.addHistogramSeries({
            color: '#182233',
            lineWidth: 2,
            priceScaleId: '',
            priceFormat: {
              type: 'custom',
              formatter : (price) => {return price.toFixed(2) + ' LBC';},
            },
            overlay: true,
            scaleMargins: {
              top: 0.8,
              bottom: 0,
            },
          });


          if (this.props.volumes.length > 0) {
            this.lastVolume = this.props.volumes[this.props.volumes.length - 1];
            console.log("sukki")
            this.setSkew = true;
            console.log(this.props.volumes[this.props.volumes.length - 1])
            console.log(this.lastVolume)
        }
          this.volumes.current.setData(this.props.volumes);
        }
      }
      if ((prevProps.priceDollarAvax !== this.props.priceDollarAvax ||
          prevProps.priceHistory !== this.props.priceHistory) &&
          this.props.priceDollarAvax != 0 && this.props.priceHistory.length != 0) {
            console.log(this.props.priceHistory[0].value);
            var prices = this.props.priceHistory.map((e) => { return {time: e.time, value : +((this.props.priceDollarAvax * e.value).toFixed(2))}})
              console.log(this.props.priceDollarAvax);
            console.log(prices);
              console.log("DUHDUIAE")
            this.areas.current.setData(prices);

            var price = 0.26

            if (prices.length > 0) {
              price = prices[prices.length - 1].value;
            }
    
            var percentage = ((price / 0.26 * 100)- 100).toFixed(2)

            this.legenden.innerHTML = '<div class="legend-chart">LBC/USD <span class="legend-price">'+ price +'</span> <span class="legend-price">+'+ percentage +'% (all)</span></div>';
      }
      if (this.areas.current && prevProps.newPrice != this.props.newPrice) {
        console.log("RHAPSODIE")
        console.log(newPrice);
        console.log(this.props.priceDollarAvax);
        var newPrice = this.props.newPrice;
        if (Object.keys(newPrice).length > 0) {
          newPrice.value = +((this.props.newPrice.value * this.props.priceDollarAvax).toFixed(2))
          if (this.props.priceDollarAvax > 0) {
            this.areas.current.update(newPrice);

            var price = 0.26;
            
            price = newPrice.value;
    
            var percentage = ((price / 0.26 * 100)- 100).toFixed(2)

            this.legenden.innerHTML = '<div class="legend-chart">LBC/USD <span class="legend-price">'+ price +'</span> <span class="legend-price">+'+ percentage +'% (all)</span></div>';
          }
        }
      }
      if (this.volumes.current && prevProps.newVolume != this.props.newVolume) {
        if (Object.keys(this.props.newVolume).length <= 0)
          return;
        console.log("DAYYSS")
        if (this.lastVolume && this.setSkew)
          this.lastVolume.time = moment(this.lastVolume.time).subtract(1, "months").format("YYYY-MM-DD");
        console.log();
        console.log(this.props.newVolume);
        if (this.lastVolume === undefined)
          this.lastVolume = this.props.newVolume;
        else if (moment(this.props.newVolume.time).isSame(this.lastVolume.time, 'day'))
          this.lastVolume.value += this.props.newVolume.value;
        else
          this.lastVolume = this.props.newVolume;

        if (this.props.priceDollarAvax > 0) {
          this.volumes.current.update(this.lastVolume);
        }
      }
    }

    componentDidMount() {

        getPriceHistory(this.props.dispatch, this.props.exchange);

        getVolumes(this.props.dispatch, this.props.exchange);

        console.log("chartContainerRef")
        console.log(this.chartContainerRef.current);

        this.chart.current = createChart(this.chartContainerRef.current, {
          width: this.chartContainerRef.current.clientWidth,
          height: this.chartContainerRef.current.clientHeight,
          handleScroll: true,
          handleScale: true,
          localization: {
            //priceFormatter: (price) => {return '$' + price.toFixed(2);},
          },
          layout: {
            backgroundColor: '#212529',
            textColor: 'rgba(255, 255, 255, 0.9)',
          },
          grid: {
            vertLines: {
              color: '#334158',
            },
            horzLines: {
              color: '#334158',
            },
          },
          crosshair: {
            mode: CrosshairMode.Normal,
          },
          priceScale: {
            borderColor: '#485c7b',
          },
          timeScale: {
            borderColor: '#485c7b',
          },
        });

        console.log(this.chart.current);

        this.areas.current = this.chart.current.addAreaSeries({
          priceFormat: {
            type: 'custom',
            formatter : (price) => {return '$' + price.toFixed(2);},
          },
          topColor: 'rgba(21, 101, 192, 0.5)',
          bottomColor: 'rgba(21, 101, 192, 0.5)',
          lineColor: 'rgba(255, 255, 255, 0.8)',
          lineWidth: 2,
        });

        this.areas.current.setData([{ time: '2022-03-04', value: 0 }]);

        const candleSeries = this.chart.current.addCandlestickSeries({
          upColor: '#4bffb5',
          downColor: '#ff4976',
          borderDownColor: '#ff4976',
          borderUpColor: '#4bffb5',
          wickDownColor: '#838ca1',
          wickUpColor: '#838ca1',
        });

        //candleSeries.setData(priceData);

        this.resizeObserver.current = new ResizeObserver(entries => {

            const { width, height } = entries[0].contentRect;

            console.log("width : " + width);
            console.log("height: " + height);
            this.chart.current.applyOptions({ width, height });
            this.chart.current.timeScale().fitContent();
        });

        var price = 0.26

        if (this.props.priceHistory.length > 0) {
          price = this.priceHistory[this.priceHistory.length - 1];
        }

        var percentage = ((price / 0.26 * 100)- 100).toFixed(2)

        this.legenden = document.createElement('div');
        this.legenden.innerHTML = '<div class="legend-chart">LBC/USD <span class="legend-price">'+ price +'</span> <span class="legend-price">+'+ percentage +'% (all)</span></div>';

        this.chartContainerRef.current.appendChild(
          this.legenden
         );

        this.resizeObserver.current.observe(this.chartContainerRef.current);

    }

    render() {
        console.log("Trading chart values");
        console.log(this.props.priceChart);

        return(
            <div className="card bg-dark text-white">
            <div ref={this.container} className="card-body">
              <div ref={this.chartContainerRef} className="price-chart price-chart-size" />
              {
                this.props.priceDollarAvax != 0 && this.props.priceHistory.length != 0 ?
                "" :
                <div className="overlayLoading">
                  <Spinner className="spinnerLoading"  animation="border" role="status">
                  <span className="visually-hidden">Loading Chart...</span>
                  </Spinner>
                  </div>
              }
            </div>
          </div>
        );
    }
}

function mapStateToProps(state) {

    return {
      exchange: exchangeSelector(state),
      newVolume: newVolumeAddedSelector(state),
      newPrice: newPriceAddedSelector(state),
      priceDollarAvax: priceAvaxUSDLoadedSelector(state),
      priceHistory: priceHistoryLoadedSelector(state),
      volumes: volumesLoadedSelector(state)
    }
  }

export default connect(mapStateToProps)(IdoChart)
export const ETHER_ADDRESS = '0x0000000000000000000000000000000000000000'
export const GREEN = 'success'
export const RED = 'danger'

export const DECIMALS = (10**18)

// Shortcut to avoid passing around web3 connection
export const ether = (wei) => {
  if(wei) {
    return(wei / DECIMALS) // 18 decimal places
  }
}

// Tokens and ether havesame decimal resolution
export const tokens = ether

// Checks if number is decimal, returns false if it isn't
export const checkIfDecimal = (number) => {
  return Number.isFinite(number);
}

export const formatWalletAddrSmallFormat = (addr) => {
  if (!addr || addr == "")
    return "";
  return "0x" + addr[2] + addr[3] + addr[4] + "..." + addr[addr.length-3] + addr[addr.length-2] + addr[addr.length-1];
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Spinner from './Spinner'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  filledOrdersLoadedSelector,
  filledOrdersSelector,
  pruchaseCompletedListSelector
} from '../store/selectors'
import moment from 'moment'

const showFilledOrders = (filledOrders) => {
  return(
    <tbody style={{maxHeight: "830px"}}>
      { filledOrders.map((order, index) => {
        return(
          <tr className={`order-book-order`} key={index}>
            <td>{order.tokenAmount}</td>
            <td className={`text-success`}>{order.tokenPrice}</td>
            <OverlayTrigger
            key={index}
            placement='bottom'
            overlay={ 
              <Tooltip>
              {order.fullTimestamp}
              </Tooltip>
             }
            >
              <td className="text-muted">{order.formattedTimestamp}</td>
            </OverlayTrigger>
          </tr>
        )
      }) }
    </tbody>
  )
}

const noOrders = () => {
  return(
  <p>No trade has been made yet</p>
  )
}

const genOrders = () => {
  var orders = [];

  var curDate = new Date(1642554000000);
  var endDate = new Date(1644282000000);
  var tokenPrice = 0.25
  var id = 1;

  while (curDate < endDate) {
    var nbOrders = Math.floor(Math.random() * 25) + 2
    var curDateBack = curDate;

    for (var i = 0; i < nbOrders; i++) {

      curDateBack.setMinutes(curDate.getMinutes() + ((Math.floor(Math.random() * 50) + 1)))

      var tokenAmount = Math.floor(Math.random() * 5000000) + 10000
      tokenAmount /= 100
      orders.push({tokenPrice: (tokenPrice / 74.28).toFixed(4), tokenAmount: tokenAmount, id: id,
        formattedTimestamp: moment(curDateBack).format('h:mm:ss a'),
        fullTimestamp: moment(curDateBack).format('Y-M-D h:mm:ss a')
      })

      id += 1
    }

    tokenPrice += 0.01
    curDate.setDate(curDate.getDate() + 1);
  }

  orders.reverse();

  return orders;
}

class Trades extends Component {
  render() {
    return (
      <div className="vertical">
        <div className="card bg-dark text-white">
          <div className="card-header">
            Trades
          </div>
          <div className="card-body">
            <table className="table table-dark table-sm small">
              <thead>
                <tr>
                  <th>LBC</th>
                  <th>LBC/AVAX</th>
                  <th>Time</th>
                </tr>
              </thead>
              { this.props.purchaseCompletedList ? showFilledOrders(this.props.purchaseCompletedList) : <Spinner type="table" />}
            </table>
            { this.props.purchaseCompletedList && this.props.purchaseCompletedList.length === 0 ? noOrders() : "" } 
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    purchaseCompletedList: pruchaseCompletedListSelector(state)
  }
}

export default connect(mapStateToProps)(Trades)

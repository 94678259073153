import Web3 from 'web3'
import { contractNotReady } from '../store/actions/Metamask';
import { avaxPriceConfigured, currentCapLoaded, lbcLeftAmountLoaded, lbcThresholdLeftAmountLoaded, priceAvaxLoaded, priceAvaxUSDLoaded, priceDollarComputed, saleStatusInitialized } from '../store/actions/SaleStatus';

export const loadSaleStatus = async (dispatch, exchange) => {

    console.log("Avax price loading");
    console.log("RATIOO : " + Web3.utils.fromWei(await exchange.methods._currentRatio().call(), 'Gwei'))
    var priceAvax = (1 / Web3.utils.fromWei(await exchange.methods._currentRatio().call(), 'Gwei'));

    if (!isFinite(priceAvax)) {
        dispatch(contractNotReady());
        return;
    }

    dispatch(priceAvaxLoaded(priceAvax.toFixed(5)))
    var lbcLeft = Web3.utils.fromWei(await exchange.methods.TokenAmount().call());
    dispatch(lbcLeftAmountLoaded(Number(lbcLeft).toFixed(2)));

    var currentCap = Math.trunc((2515000 - lbcLeft) / 20120) + 1;
    dispatch(currentCapLoaded(currentCap));

    var avaxPrice = (await exchange.methods.AvaxPrice().call() / 100).toFixed(2);
    dispatch(avaxPriceConfigured(avaxPrice));

    var lbcThresholdLeft = Number(Web3.utils.fromWei(await exchange.methods.CurrentStep().call()))

    console.log("troudball");

    console.log(lbcThresholdLeft);
    dispatch(lbcThresholdLeftAmountLoaded(lbcThresholdLeft));

    fetch("https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=usd")
    .then(res => res.json())
    .then(res => {
        var priceUSD = res["avalanche-2"]?.usd;
        var priceLBCUSD = priceUSD * priceAvax;

        dispatch(priceAvaxUSDLoaded(priceUSD));
        dispatch(priceDollarComputed(priceLBCUSD.toFixed(2)));
        dispatch(saleStatusInitialized());
    })
}
// Has computed the price in dollars of 1 LBC
export function priceDollarComputed(price) {
    return {
      type: 'PRICE_DOLLAR_COMPUTED',
      price
    }
}

export function priceAvaxUSDLoaded(price) {
    return {
      type: 'PRICE_AVAX_USD_LOADED',
      price
    }
}

export function priceAvaxLoaded(price) {
    return {
      type: 'PRICE_AVAX_LOADED',
      price
    }
}

export function avaxPriceConfigured(price) {
    return {
        type: 'PRICE_AVAX_CONFIGURED',
        price
    }
}

export function lbcLeftAmountLoaded(amount) {
    return {
      type: 'LBC_LEFT_AMOUNT_LOADED',
      amount
    }
}

export function lbcThresholdLeftAmountLoaded(amount) {
    return {
      type: 'LBC_THRESHOLD_LEFT_AMOUNT_LOADED',
      amount
    }
}

export function currentCapLoaded(number) {
    return {
      type: 'CURRENT_CAP_LOADED',
      number
    }
}

export function saleStatusInitialized() {
    return {
        type: 'SALE_STATUS_INITIALIZED'
    }
}

import React, { Component } from 'react'
import './App.css'
import Navbar from './Navbar'
import Content from './Content'
import { connect } from 'react-redux'
import { accountSelector, connectedSelector, contractsLoadedSelector } from '../store/selectors'

import {
  accountConnect,
  loadWeb3,
  loadAccount,
  loadToken,
  loadExchange
} from '../store/interactions'

import Connect from './Connect'

class App extends Component {

  async loadBlockchainData(dispatch) {
    const web3 = await loadWeb3(dispatch)
    const networkId = await web3.eth.net.getId()
    await loadAccount(web3, dispatch)
    const token = await loadToken(web3, networkId, dispatch)
    if(!token) {
      window.alert('Token smart contract not detected on the current network. Please select another network with Metamask.')
      return
    }
    const exchange = await loadExchange(web3, networkId, dispatch)
    if(!exchange) {
      window.alert('Exchange smart contract not detected on the current network. Please select another network with Metamask.')
      return
    }
  }

  async componentWillMount() {
    await accountConnect(this.props.dispatch);
    if (this.props.connected) {
        console.log("Account already connected")
        try {
        this.loadBlockchainData(this.props.dispatch);
        } catch (e) {
          console.log("HDDHDHGDGEGEG")
        }
    }
  }

  render() {

    return (
      <div>
        <Navbar />
        { !this.props.connected ? <Connect /> : ''}
        { this.props.connected && this.props.contractsLoaded ? <Content /> : '' }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    connected: connectedSelector(state),
    contractsLoaded: contractsLoadedSelector(state)
  }
}

export default connect(mapStateToProps)(App)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Spinner from './Spinner'
import { Col, OverlayTrigger, Row, Tooltip, Badge, Container, ProgressBar, Modal, Button } from 'react-bootstrap'
import {
  accountSelector,
  etherBalanceSelector,
  exchangeSelector,
  filledOrdersLoadedSelector,
  filledOrdersSelector,
  priceAvaxLoadedSelector,
  purchaseProcessingSelector,
  purchaseStatusSelector,
  tokenSelector,
  walletLbcBalanceSelector,
  web3Selector
} from '../store/selectors'

import LogoLBC from '../assets/logo_lbc.png'
import { getWalletLBCBalance } from '../services/lbc.service'
import { loadBalance } from '../services/web3.service'
import { buyLBC } from '../services/decentralizedOffering.service'
import { purchaseStatus } from '../store/actions/DecentralizedOffering'
import { loadSaleStatus } from '../services/sale.service'
import { formatWalletAddrSmallFormat } from '../helpers'

const showFilledOrders = (filledOrders) => {
  return(
    <tbody>
      { filledOrders.map((order) => {
        return(
          <tr className={`order-${order.id}`} key={order.id}>
            <td className={`text-${order.tokenPriceClass}`}>{order.tokenPrice}</td>
            <td>{order.tokenAmount}</td>
            <OverlayTrigger
            key={order.id}
            placement='bottom'
            overlay={ 
              <Tooltip>
              {order.fullTimestamp}
              </Tooltip>
             }
            >
              <td className="text-muted">{order.formattedTimestamp}</td>
            </OverlayTrigger>
          </tr>
        )
      }) }
    </tbody>
  )
}

const noOrders = () => {
  return(
  <p>No trade has happened yet</p>
  )
}

class BuyLBC extends Component {

  constructor() {
    super();
    this.state = {
      lbcAmount: 0,
      avaxAmount: 0,
      purchasePreview: false,
      purchaseSuccess: false
    }
  }

  handleClose = () => {this.props.dispatch(purchaseStatus(""));this.setState({purchasePreview: false})}
  handleShow = () => {this.setState({purchasePreview: true})}

  handlePurchaseSuccessClose = () => {this.setState({purchaseSuccess: false})}
  handlePurchaseSuccessShow = () => {this.setState({purchaseSuccess: true})}

  async componentWillMount() {
    await getWalletLBCBalance(this.props.dispatch, this.props.token, this.props.account);
    await loadBalance(this.props.dispatch, this.props.web3, this.props.account);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.purchaseStatus !== this.props.purchaseStatus && this.props.purchaseStatus === "success" && !this.state.purchaseSuccess) {
      this.handleClose();
      this.handlePurchaseSuccessShow();
      setTimeout(() => {
        getWalletLBCBalance(this.props.dispatch, this.props.token, this.props.account);
        loadBalance(this.props.dispatch, this.props.web3, this.props.account);
      }, 10000);
    }
  }

  render() {

    return (
      <div className="vertical">
        <div className="card bg-dark text-white">
          <div className="card-header">
            Buy LBC
          </div>
          <div className="card-body">
          <table className="table table-dark table-sm small">
            <thead>
              <tr>
                <th>Wallet</th>
                <th>AVAX</th>
                <th>LBC</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatWalletAddrSmallFormat(this.props.account)}</td>
                <td>{this.props.avaxBalance}</td>
                <td>{this.props.lbcBalance}</td>
              </tr>
            </tbody>
          </table>
          <Container>
            <Col className="d-flex justify-content-center"> 
                <button onClick={() => this.setState({avaxAmount : (1000 * this.props.priceAvaxLBC).toFixed(5), lbcAmount: 1000 })} className="btn btn-hlb btn-block">Get 1000 LBC</button>
            </Col>
            <Col className="d-flex justify-content-center mt-2"> 
                <button onClick={() => this.setState({avaxAmount : (2500 * this.props.priceAvaxLBC).toFixed(5), lbcAmount: 2500 })} type="submit" className="btn btn-hlb btn-block">Get 2500 LBC</button>
            </Col>
            <Col className="d-flex justify-content-center mt-2 mb-3"> 
                <button onClick={() => this.setState({avaxAmount : (5000 * this.props.priceAvaxLBC).toFixed(5), lbcAmount: 5000 })} type="submit" className="btn btn-hlb btn-block">Get 5000 LBC</button>
            </Col>
              <form onSubmit={(e) => {
                e.preventDefault();
                this.handleShow();
              }}>
                <div className="col-12 col-sm pr-sm-2 mb-2">
                  <span>Avax Amount</span>
                  <input
                  type="number"
                  value={this.state.avaxAmount}
                  step="0.0001"
                  min="0.1"
                  placeholder="AVAX Amount"
                  onChange={(e) => {
                      var value = e.target.value < 0 ? -e.target.value : e.target.value;
                     this.setState({lbcAmount : ((1 / this.props.priceAvaxLBC) * value).toFixed(2), avaxAmount: value })
                    }}
                  className="form-control form-control-sm bg-dark text-white"
                  required />
                </div>
                <div className="col-12 col-sm pr-sm-2 mb-3">
                  <span>LBC Amount</span>
                  <input
                  type="number"
                  value={this.state.lbcAmount}
                  step="0.0001"
                  placeholder="LBC Amount"
                  onChange={(e) => { 
                    var value = e.target.value < 0 ? -e.target.value : e.target.value;
                    this.setState({avaxAmount : (value * this.props.priceAvaxLBC).toFixed(5), lbcAmount: value })}}
                  className="form-control form-control-sm bg-dark text-white"
                  required />
                </div>
                <Col className="d-flex justify-content-center"> 
                  <button type="submit" disabled={this.state.avaxAmount > this.props.avaxBalance} className="btn btn-success btn-block btn-sm">Preview Purchase</button>
                </Col>
              </form>
          </Container>
          </div>
        </div>
        <Modal show={this.state.purchaseSuccess} onHide={this.handlePurchaseSuccessClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>LBC Purchase Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <h2>Congratulations!</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>You've successfully acquired some LBC tokens! Your balance will update in a few seconds when your transaction is confirmed.</span>
                  
                  <p className="mt-2">Don't forget to add the LBC Token to your wallet : <strong>0x4d52472eDa719b40e726A48920C4aB2E290D5946</strong></p>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.props.processingPurchase} variant="secondary" onClick={this.handlePurchaseSuccessClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.purchasePreview} onHide={this.handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>LBC Purchase Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                <span>Avax to Invest : </span>
                </Col>
                <Col>
                  <strong>{this.state.avaxAmount} AVAX</strong>
                </Col>
              </Row>
              <Row>
                <Col>
                <span>LBC to Retrieve : </span>
                </Col>
                <Col>
                  <strong>{this.state.lbcAmount} LBC *</strong>
                </Col>
              </Row>
              <Row className="mt-3 justify-content-center">
                  <span className="fw-light" style={{textAlign: "center", fontSize: "12px"}}>* Please note that the amount of LBC to retrieve is an approximation based on the current sale state. There can be a difference in price if a cap is reached before the purchase has completed on the blockchain, or small deltas in approximation due to rounding</span>
              </Row>
              {
              this.props.purchaseStatus == "error" ?
              (<Row className="mt-3">
                <span className="text-danger" style={{textAlign: "center"}}>An error occured while trying to purchase LBC tokens. Please check your Metamask for error. You can contact us on Telegram if you need assistance.</span>
              </Row>) : ""
              }
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.props.processingPurchase} variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button disabled={this.props.processingPurchase} variant="success" onClick={async (e) => { await buyLBC(this.props.dispatch, this.props.web3, this.props.exchange, this.props.token, this.props.account, this.state.avaxAmount)}}>
              {this.props.processingPurchase ? <Spinner /> : <span>Purchase</span>}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    token: tokenSelector(state),
    exchange: exchangeSelector(state),
    account: accountSelector(state),
    web3: web3Selector(state),
    priceAvaxLBC: priceAvaxLoadedSelector(state),
    lbcBalance: walletLbcBalanceSelector(state),
    avaxBalance: etherBalanceSelector(state),
    processingPurchase : purchaseProcessingSelector(state),
    purchaseStatus: purchaseStatusSelector(state),
  }
}

export default connect(mapStateToProps)(BuyLBC)
